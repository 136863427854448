import React, { useEffect, useState } from "react";

import classNames from "classnames";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import { useSelector } from "react-redux";

import styles from "./LanguageSwitcher.css";
import { ArrowIcon } from "../../atoms/Icons/Styleguide/ArrowIcon";
import { PageTypes } from "../../constants/Pages";
import { GameService } from "../../services/GameService";
import { LocalStorageService } from "../../services/LocalStorage";
import { PageService } from "../../services/PageService";
import { ROUTES, UrlService } from "../../services/UrlService";
import { SrOnly } from "../SrOnly/SrOnly";

const NON_EN_SUPPORTED_LOCALES = ['fr', 'de', 'br', 'nl'];
const SWITCHER_LOCALES = ['en'].concat(NON_EN_SUPPORTED_LOCALES);
const BLOCKED_LANG_SWITCH_PAGES = [PageTypes.Dynamic, PageTypes.BlogArchive, PageTypes.BlogPost, ROUTES.Subscription];

export const LanguageSwitcher = () => {
  const currentLang = useSelector(({ currentLang }) => currentLang);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const pages = useSelector(({ pages }) => pages);
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const pageType = useSelector(({ pageType }) => pageType);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const [currentLocale, setCurrentLocale] = useState<string>(currentLang);
  const [isNonEnSupported, setNonEnSupported] = useState<boolean>(true);
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(SWITCHER_LOCALES.length);
  const getIsEnSelectedLocale = (selectedLocale: string): boolean => selectedLocale === 'en';
  const getLocaleDropdownList = (currentLocale: string): string[] => SWITCHER_LOCALES.filter((locale) => locale !== currentLocale);

  useEffect(() => {
    const localStorageLocale = LocalStorageService.getItem('currentLocale');

    if (!!localStorageLocale && localStorageLocale !== currentLang) {
      switchLanguage(localStorageLocale);
    }

    if (pageType == PageTypes.Game && gameArkadiumSlug) {
      const game = GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug);
      const gameNonEnLangSupported = !!NON_EN_SUPPORTED_LOCALES.find((locale) => GameService.isLanguageSupportedByGame(game, locale));

      setNonEnSupported(gameNonEnLangSupported);
    }

    if (BLOCKED_LANG_SWITCH_PAGES.find((page) => pageType == page)) {
      setNonEnSupported(false);
    }
  }, []);

  const switchLanguage = (selectedLocale: string) => {
    let url: string;

    url = UrlService.createURL(getIsEnSelectedLocale(selectedLocale) ? '/' : `/${selectedLocale}/`, true);
    const getLangPrefix = (selectedLocale: string): string => getIsEnSelectedLocale(selectedLocale) ? '' : `/${selectedLocale}`;

    if ((pageType === PageTypes.Category || pageType === PageTypes.AllCategories) && categoryPageName) {
      const currentCategory = PageService.getPageSEOByPageName(pages, categoryPageName);
      const slug = currentCategory.slug[selectedLocale];
      let path: string;
      const pathToCategory = UrlService.getPageLocalizedRoute(selectedLocale, pageType);

      if (pageType === PageTypes.Category && categoryPageName !== 'All') {
        path = `${getLangPrefix(selectedLocale)}/${pathToCategory}/${slug}/`;
      } else {
        path = `${getLangPrefix(selectedLocale)}/${pathToCategory}/`;
      }

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.Game) {
      const game = GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug);
      const isLanguageSupportedByGame = GameService.isLanguageSupportedByGame(game, selectedLocale);
      const langPrefix = getLangPrefix(isLanguageSupportedByGame ? selectedLocale : 'en');
      const path = `${langPrefix}/games/${gameArkadiumSlug}/`;
      
      if (!isLanguageSupportedByGame) {
        LocalStorageService.setItem('currentLocale', 'en');
      }

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.Profile) {
      const path = `${getLangPrefix(selectedLocale)}/profile/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.PrivacyPolicy) {
      url = UrlService.createPrivacyPolicyURL(currentLang, plusFeatureFlag);
    }

    if (pageType === PageTypes.AboutUs) {
      const pageSeo = PageService.getPageSEOByPageName(pages, 'About Us');
      const path = `${getLangPrefix(selectedLocale)}/${pageSeo.slug[selectedLocale]}/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.SiteMap) {
      const path = `${getLangPrefix(selectedLocale)}/site-map/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.GiftCard) {
      const path = `${getLangPrefix(selectedLocale)}/gift-card/`;

      url = UrlService.createURL(path, true);
    }

    if (pageType === PageTypes.GameSubmissionDisclaimer) {
      const path = `${getLangPrefix(selectedLocale)}/game-submission-disclaimers/`;

      url = UrlService.createURL(path, true);
    }

    window.location.href = url;
  };
  const handleSelect = (selectedLocale: string) => {
    if (!isNonEnSupported) {
      return;
    }

    setCurrentLocale(selectedLocale);
    LocalStorageService.setItem('currentLocale', selectedLocale);
    //Timeout for animation
    setTimeout(() => {
      switchLanguage(selectedLocale);
    }, 200);

    setIsOpen(false);
  }

  return (
    <>
      <SrOnly/>
      <div className={styles.dropDownContainer}>
        <button
          {...buttonProps}
          className={classNames(styles.dropDownHandler)}
          disabled={!isNonEnSupported}
        >
          <div className={styles.localeItem}>
            <img
              src={UrlService.toCDNUrl(`/icons/flags-icons/${currentLocale}.png`)}
              alt={currentLocale}
              className={styles.localeItemImage}
            />
            <span>{currentLocale.toUpperCase()}</span>
          </div>
          <ArrowIcon className={classNames(styles.dropdownArrow, isOpen && styles.arrowUp)} />
        </button>
        <div role="menu" className={classNames(styles.dropDownBlock, isOpen && [styles.visible])}>
          <div className={styles.dropDownItems}>
            {getLocaleDropdownList(currentLocale).map((locale, index) => (
              <a
                {...itemProps[index]}
                className={styles.dropDownItem}
                key={index}
                onClick={() => handleSelect(locale)}
              >
                <div className={styles.localeItem}>
                  <img
                    src={UrlService.toCDNUrl(`/icons/flags-icons/${locale}.png`)}
                    alt={locale}
                    className={styles.localeItemImage}
                  />
                  <span>{locale.toUpperCase()}</span>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};