import loadable from '@loadable/component';

import { PageTypes } from './client/constants/Pages';
import { MiscUtils } from './utils/MiscUtils';

const SubscriptionPage = loadable(() =>
  MiscUtils.loadableRetry(() => import('./client/pages/PurchasePageSubscription'))
);
const GemsShopPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/PurchasePageGemsShop')));
const PrivacyPolicyPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/PrivacyPolicyPage')));
const CategoryPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/CategoryPage')));
const GamePage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/GamePage')));
const P404Page = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/404Page')));
const HomePage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/HomePage')));
const ProfilePage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/ProfilePage')));
const AboutUsPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/AboutUsPage')));
const GiftCardPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/GiftCardPage')));
const SiteMapPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/SiteMapPage')));
const DynamicPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/DynamicPage')));
const BlogArchivePage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/BlogArchivePage')));
const BlogPostPage = loadable(() => MiscUtils.loadableRetry(() => import('./client/pages/BlogPostPage')));
const GameSubmissionDisclaimerPage = loadable(() =>
  MiscUtils.loadableRetry(() => import('./client/pages/GameSubmissionDisclaimerPage'))
);

export type RouterObj = {
    exact: boolean;
    path: string;
    pageType: PageTypes;
    component: any;
};

type MappingBag = {
    [key: string]: { pageType: PageTypes; component: any };
};

type routesElements = {
    bases: string[];
    entities: MappingBag;
};

const page2RelatedEntities: MappingBag = {
    'free-online-games/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    categories: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'free-online-games/jigsaw-puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    'our-story': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    'privacy-policy-california': {
        pageType: PageTypes.PrivacyPolicyCalifornia,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'coming-soon/:slug': {
        pageType: PageTypes.ComingSoonGame,
        component: DynamicPage,
    },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesFr: MappingBag = {
    'jeux-gratuits/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    /* Check this out */
    'notre-histoire': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    categorie: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'jeux-gratuits/puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesFrDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesEs: MappingBag = {
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
};
const page2RelatedEntitiesForLanding: MappingBag = {
    '': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesDe: MappingBag = {
    'kostenlose-online-spiele/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    kategorien: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'kostenlose-online-spiele/jigsaw-puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    'unsere-geschichte': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    'privacy-policy-california': {
        pageType: PageTypes.PrivacyPolicyCalifornia,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'coming-soon/:slug': {
        pageType: PageTypes.ComingSoonGame,
        component: DynamicPage,
    },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesDeDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesBr: MappingBag = {
    'jogos-online-gratis/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    /* Check this out */
    'nossa-historia': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    categorias: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'jogos-online-gratis/puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesBrDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};
const page2RelatedEntitiesNl: MappingBag = {
    'gratis-spelletjes-online/:category?': {
        pageType: PageTypes.Category,
        component: CategoryPage,
    },
    /* Check this out */
    'ons-verhaal': { pageType: PageTypes.AboutUs, component: AboutUsPage },
    categorieen: {
        pageType: PageTypes.AllCategories,
        component: CategoryPage,
    },
    'games/:game': { pageType: PageTypes.Game, component: GamePage },
    'gratis-spelletjes-online/puzzles/:game': {
        pageType: PageTypes.Game,
        component: GamePage,
    },
    subscription: { pageType: PageTypes.Subscription, component: SubscriptionPage },
    shop: { pageType: PageTypes.Shop, component: GemsShopPage },
    'privacy-policy': {
        pageType: PageTypes.PrivacyPolicy,
        component: PrivacyPolicyPage,
    },
    profile: { pageType: PageTypes.Profile, component: ProfilePage },
    'profile/:userId': {
        pageType: PageTypes.Profile,
        component: ProfilePage,
    },
    blog: {
        pageType: PageTypes.BlogArchive,
        component: BlogArchivePage,
    },
    'game-submission-disclaimers': {
        pageType: PageTypes.GameSubmissionDisclaimer,
        component: GameSubmissionDisclaimerPage,
    },
    'blog/:blogPost': { pageType: PageTypes.BlogPost, component: BlogPostPage },
    'gift-card': { pageType: PageTypes.GiftCard, component: GiftCardPage },
    'site-map': { pageType: PageTypes.SiteMap, component: SiteMapPage },
    '404': { pageType: PageTypes.NotFound, component: P404Page },
    '': { pageType: PageTypes.Home, component: HomePage },
};
const page2RelatedEntitiesNlDynamic: MappingBag = {
    ':slug': { pageType: PageTypes.Dynamic, component: DynamicPage },
};

function generateRoutes({ bases, entities }: routesElements): RouterObj[] {
    const routes: RouterObj[] = [];
    const pages = Object.keys(entities);

    for (const base of bases) {
        const newRoutes = pages.map((page) => {
            const { pageType, component } = entities[page];

            return { exact: true, path: `${base}${page}`, pageType, component };
        });

        routes.push(...newRoutes);
    }

    return routes;
}

function prepareRoutes(routesElement: routesElements[]) {
    const routes: RouterObj[] = [];

    routesElement.forEach((route) => {
        routes.push(...generateRoutes(route));
    });

    routes.push({
        exact: false,
        path: '*',
        pageType: PageTypes.NotFound,
        component: P404Page,
    });

    return routes;
}

const COMMON_BASES_EN = ['/', ...['en', '__empty__'].map((l) => `/${l}/`)];
const COMMON_BASES_FR = ['/fr/'];
const COMMON_BASES_ES = ['/es/'];
const COMMON_BASES_DE = ['/de/'];
const COMMON_BASES_BR = ['/br/'];
const COMMON_BASES_NL = ['/nl/'];

export const routes = prepareRoutes([
    { bases: COMMON_BASES_FR, entities: page2RelatedEntitiesFr },
    { bases: COMMON_BASES_ES, entities: page2RelatedEntitiesEs },
    { bases: COMMON_BASES_DE, entities: page2RelatedEntitiesDe },
    { bases: COMMON_BASES_BR, entities: page2RelatedEntitiesBr },
    { bases: COMMON_BASES_NL, entities: page2RelatedEntitiesNl },
    { bases: COMMON_BASES_EN, entities: page2RelatedEntities },
    { bases: COMMON_BASES_FR, entities: page2RelatedEntitiesFrDynamic },
    { bases: COMMON_BASES_DE, entities: page2RelatedEntitiesDeDynamic },
    { bases: COMMON_BASES_BR, entities: page2RelatedEntitiesBrDynamic },
    { bases: COMMON_BASES_NL, entities: page2RelatedEntitiesNlDynamic },
    { bases: COMMON_BASES_EN, entities: page2RelatedEntitiesDynamic },
]);

export const landingRoutes = prepareRoutes([
    {bases: COMMON_BASES_EN, entities: page2RelatedEntitiesForLanding},
])
